import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.lenis = null;
        this.hash = null; // Variable to store the hash
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        this.isLoader = document.querySelector(".c-loader");

        if (!this.isLoader) {
            this.lenis.start();
        }

        this.loader = document.querySelectorAll(".c-loader svg path");

        // Convert NodeList to Array for easy shuffling and manipulation
        const charsArray = Array.from(this.loader);
        gsap.utils.shuffle(charsArray);  // Shuffling the array for random order

        // Animating each character using forEach
        charsArray.forEach((char, index) => {
            gsap.to(char, {
                duration: 0.15,
                opacity: 0,
                ease: "none",
                delay: index * 0.0165 + 0.85,
                onStart: () => {
                    ScrollTrigger.refresh();
                },
                onComplete: () => {
                    if (index === charsArray.length - 1) {
                        if (this.isLoader) {
                            this.isLoader.classList.add("point-none");
                            document.documentElement.classList.add("is-first-loaded");
                            document.documentElement.classList.remove("is-first-loading");
                        }

                        if (this.isLoader) {
                            setTimeout(() => {
                                const loader = document.querySelector(".c-loader");
                                if (loader) loader.remove();
                            }, 1000);
                        }
                    }
                }
            });
        });
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    destroy() {
        this.lenis.destroy();
    }
}
