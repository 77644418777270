import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.currentYear = new Date().getFullYear();

        this.el.textContent = this.currentYear;
    }

    destroy() {
        // Safely clear the content of all matched elements if any exist
        if (this.el) {
            this.el.textContent = '';
        }
    }
}
