import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import InertiaPlugin from 'gsap/InertiaPlugin';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const media = gsap.matchMedia();

        media.add("(max-width: 999px)", () => {
            // Circle animation
            gsap.registerPlugin(DrawSVGPlugin);

            // Slide animation
            gsap.registerPlugin(Draggable, InertiaPlugin);

            const slidesContainer = document.querySelector(".c-slides-container");
            const slides = gsap.utils.toArray(".c-slide");
            const heroStepsOne = document.querySelector(".c-hero_mobile-steps .-one");
            const heroStepsTwo = document.querySelector(".c-hero_mobile-steps .-two");
            const roundButton = document.querySelector(".c-button.-round");
            const transparentButton = document.querySelector(".c-button.-transparent");
            let snapPoints = slides.map((_, i) => -(window.innerWidth * i));
            let direction;
            let currentSlide = 0;
            let draggableInstance;
            let circleTimeline;

            const updateActiveClass = () => {
                if (currentSlide === 0) {
                    heroStepsOne.classList.add('-active');
                    heroStepsTwo.classList.remove('-active');
                    transparentButton.href = "https://calendar.app.google/hpcAJRLEgbwBj19o8"; // Update link for slide 0
                    transparentButton.classList.remove('-slide-2'); // Remove class for slide 0
                } else if (currentSlide === 1) {
                    heroStepsOne.classList.remove('-active');
                    heroStepsTwo.classList.add('-active');
                    transparentButton.href = "https://darkroom.kaploom.com/"; // Update link for slide 1
                    transparentButton.classList.add('-slide-2'); // Add class for slide 1
                }
            };

            const goToSlide = (index) => {
                if (index >= 0 && index < slides.length) {
                    currentSlide = index;
                    updateActiveClass();
                    gsap.to(slidesContainer, {
                        x: snapPoints[currentSlide],
                        duration: 1,
                        ease: "expo.out"
                    });
                }
            };

            const nextSlide = () => {
                goToSlide((currentSlide + 1) % slides.length);
            };

            const resetCircleAnimation = () => {
                circleTimeline.pause();
                gsap.to(".circle", {
                    drawSVG: "0%",
                    opacity: 0,
                    duration: 1,
                    ease: "expo.out",
                    onComplete: () => circleTimeline.restart(true)
                });
            };

            const createDraggable = () => {
                if (draggableInstance) {
                    draggableInstance.kill();
                }

                draggableInstance = Draggable.create(slidesContainer, {
                    type: "x",
                    bounds: {
                        minX: snapPoints[slides.length - 1],
                        maxX: 0
                    },
                    onDrag: function () {
                        direction = this.deltaX;
                        resetCircleAnimation();
                    },
                    inertia: true,
                    snap: {
                        x: () => {
                            if (direction < 0 && currentSlide < slides.length - 1) {
                                currentSlide++;
                            } else if (direction > 0 && currentSlide > 0) {
                                currentSlide--;
                            }
                            updateActiveClass();
                            return snapPoints[currentSlide];
                        }
                    }
                })[0]; // Draggable.create returns an array
            };

            // Timeline for the circle animation
            circleTimeline = gsap.timeline({repeat: -1, onComplete: nextSlide});
            circleTimeline.fromTo(".circle", {drawSVG: "0%", opacity: 1}, {
                drawSVG: "100%",
                opacity: 1,
                duration: 10,
                ease: "none",
                onComplete: nextSlide
            });

            roundButton.addEventListener('click', () => {
                resetCircleAnimation();
                goToSlide((currentSlide + 1) % slides.length);
            });

            slidesContainer.addEventListener('pointerdown', resetCircleAnimation);
            slidesContainer.addEventListener('touchstart', resetCircleAnimation);
            slidesContainer.addEventListener('mousedown', resetCircleAnimation);

            createDraggable();

            const updateSnapPoints = () => {
                snapPoints = slides.map((_, i) => -(window.innerWidth * i));
                createDraggable();
                goToSlide(currentSlide); // Ensure the slider resizes correctly based on the current slide
            };

            window.addEventListener('resize', updateSnapPoints);

            // Return a cleanup function
            return () => {
                if (draggableInstance) {
                    draggableInstance.kill();
                }
                if (circleTimeline) {
                    circleTimeline.kill();
                }
                window.removeEventListener('resize', updateSnapPoints);
                roundButton.removeEventListener('click', resetCircleAnimation);
                slidesContainer.removeEventListener('pointerdown', resetCircleAnimation);
                slidesContainer.removeEventListener('touchstart', resetCircleAnimation);
                slidesContainer.removeEventListener('mousedown', resetCircleAnimation);
            };
        });
    }

    destroy() {
        window.removeEventListener('resize', updateSnapPoints);
        if (draggableInstance) {
            draggableInstance.kill();
        }
    }
}
