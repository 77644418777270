import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.updateTime(); // Update immediately to ensure we're showing the current time
        
        // Calculate delay until the next full minute
        const now = new Date();
        const delayUntilNextMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

        // Wait until the start of the next minute to start the interval
        setTimeout(() => {
            this.updateTime(); // Update again at the start of the next minute
            this.interval = setInterval(() => this.updateTime(), 60000); // Continue updating every minute
        }, delayUntilNextMinute);
    }

    updateTime() {
        const rigaTimezone = 'Europe/Riga';
        const nowInRiga = new Date().toLocaleString('en-US', { timeZone: rigaTimezone });
        // Specify options for a 12-hour clock without leading zeros and without seconds
        const timeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(new Date(nowInRiga));

        const currentTimeElement = this.el.querySelector('.lv-time');
        if (currentTimeElement) {
            currentTimeElement.textContent = formattedTime;
        }
    }

    destroy() {
        clearInterval(this.interval);
    }
}
