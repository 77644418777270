import { module } from 'modujs';
import { CSS_CLASS } from "../config";
import { loadImage } from '../utils/image';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // Assuming 'this.el' refers to the image element or you have a way to get the image URL
        // You might need to adjust this to fit how your module is designed to work with images
        const imageUrl = this.el.dataset.src; // Example: getting the URL from data-src attribute

        loadImage(imageUrl)
            .then((imageData) => {
                // imageData.element is the loaded image element
                // Here, you can add a class to the image element to indicate it has loaded
                // imageData.element.classList.add(CSS_CLASS.LOADED);

                // If you want to work directly with the module's element (this.el) and it's an IMG
                if (this.el.tagName === 'IMG') {
                    this.el.src = imageData.url;
                    this.el.onload = () => {
                        this.el.classList.add(CSS_CLASS.LOADED);
                        // ScrollTrigger.refresh();
                    };
                }
            })
            .catch((error) => {
                console.error('Image loading failed:', error);
                // Handle the error, maybe add a different class or retry logic
            });
    }
}
