import gsap from "gsap";
import { module } from 'modujs';
import MouseFollower from "mouse-follower";

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        if (this.isMobileDevice()) {
            return;
        }
    
        MouseFollower.registerGSAP(gsap);
    
        this.cursor = new MouseFollower({
            container: document.querySelector("[data-load-container]"),
            className: "c-cursor",
            innerClassName: "c-cursor-inner",
            textClassName: "c-cursor-text",
            mediaClassName: "c-cursor-media",
            mediaBoxClassName: "c-cursor-media-box",
            skewing: 0,
            skewingText: 1,
            skewingIcon: 1,
            speed: 0.35,
            overwrite: true,
        });
    
        this.setupHoverEffects();
    
        this.handleMouseClickBound = this.handleMouseClick.bind(this);
        document.addEventListener('click', this.handleMouseClickBound);
    }
    
    
    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    setupHoverEffects() {
        const cursorText = document.querySelector('.c-cursor-text');
        
        // PRO LINK IN THE FOOTER
        const proLink = document.querySelectorAll('.c-footer_boxes-pro-link');
        const contentPro = `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="c-cursor-text-icon">
                            <g>
                              <path d="M5.83341 15.5001L4.66675 14.3334L12.6667 6.33341H5.50008V4.66675H15.5001V14.6667H13.8334V7.50008L5.83341 15.5001Z" fill="currentColor"></path>
                            </g>
                         </svg>
                         <p>GO PRO</p>`;

        proLink.forEach(link => {
            link.addEventListener('mouseenter', () => {
                cursorText.innerHTML = contentPro; // Insert SVG when hovered
            });
        });
        

        // CORE LINK IN THE FOOTER
        const coreLink = document.querySelectorAll('.c-footer_boxes-core-link');
        const contentCore = `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="c-cursor-text-icon">
                         <g>
                           <path d="M5.83341 15.5001L4.66675 14.3334L12.6667 6.33341H5.50008V4.66675H15.5001V14.6667H13.8334V7.50008L5.83341 15.5001Z" fill="currentColor"></path>
                         </g>
                      </svg>
                      <p>GET CORE</p>`;

        coreLink.forEach(link => {
            link.addEventListener('mouseenter', () => {
                cursorText.innerHTML = contentCore; // Insert SVG when hovered
            });
        });

        // PLANS HEADINGS SWITCH
        const switchArea = document.querySelectorAll('.c-plans_headings');
        const contentSwitch = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="c-cursor-text-icon -rotate">
                    <g>
                    <path d="M5.1 16.05C4.73333 15.4167 4.45833 14.7667 4.275 14.1C4.09167 13.4333 4 12.75 4 12.05C4 9.81667 4.775 7.91667 6.325 6.35C7.875 4.78333 9.76667 4 12 4H12.175L10.575 2.4L11.975 1L15.975 5L11.975 9L10.575 7.6L12.175 6H12C10.3333 6 8.91667 6.5875 7.75 7.7625C6.58333 8.9375 6 10.3667 6 12.05C6 12.4833 6.05 12.9083 6.15 13.325C6.25 13.7417 6.4 14.15 6.6 14.55L5.1 16.05ZM12.025 23L8.025 19L12.025 15L13.425 16.4L11.825 18H12C13.6667 18 15.0833 17.4125 16.25 16.2375C17.4167 15.0625 18 13.6333 18 11.95C18 11.5167 17.95 11.0917 17.85 10.675C17.75 10.2583 17.6 9.85 17.4 9.45L18.9 7.95C19.2667 8.58333 19.5417 9.23333 19.725 9.9C19.9083 10.5667 20 11.25 20 11.95C20 14.1833 19.225 16.0833 17.675 17.65C16.125 19.2167 14.2333 20 12 20H11.825L13.425 21.6L12.025 23Z" fill="currentColor"/>
                    </g>
                    </svg>
                    <p>SWITCH</p>`;

        switchArea.forEach(link => {
            link.addEventListener('mouseenter', () => {
                cursorText.innerHTML = contentSwitch; // Insert SVG when hovered
            });
        });
    }

    handleMouseClick(event) {
        const cursorElement = document.querySelector('.c-cursor');
        if (cursorElement && cursorElement.classList.contains('-icon')) {
            if (this.cursor) { // Check if this.cursor is not null
                this.cursor.removeState('-icon');
            }
        }
    }
    
    destroy() {
        if (this.isMobileDevice()) {
            return;
        }
    
        if (this.cursor) {
            this.cursor.destroy();
            this.cursor = null;
        }
    
        document.removeEventListener('click', this.handleMouseClickBound);
    }
    
    
}
